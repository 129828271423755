//import { fetchFillComarc } from './fetchFillComarc';
import { getUUID } from './getUuid';
import { handleButtonClick } from './handleButtonClick';
import { applyDescriptor } from './applyDescriptor';
import { jsonPayload, transformPayload } from './jsonStructure';

function postRecord() {
    const url = 'https://127.0.0.1/api/records';
    const data = jsonPayload()
  
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ipUjYZfWwsKoWiuc6QX1mB8OlNzLYmonPIcl4R4fpypY5k9Zpv2uAifTRPAM'
      },
      body: JSON.stringify(data),
      // For self-signed certificates, you might need to add this option
      // mode: 'no-cors'
    })
    .then(response => response.json())
    .then(data => console.log('Success:', data))
    .catch(error => console.error('Error:', error));
}

/*
async function fetchFillComarc() {
	const cid = document.getElementById('inputCobissId').value;
	const uid = document.getElementById('currentUserId').value;
	if (!cid) {
		alert("Prosim vstavi COBISS ID");
		return;
	}
	if (!uid) {
        alert("Prosim prijavi se");
        return;
	}
	try {
		const response = await fetch(`/process_data`, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
			},
			body: JSON.stringify({cid: cid, uid: uid}),
		})
		.then(response => response.json())
		.then(data => {
			console.log(data)
			if (data.status_code == 201) {
				//window.location.href = data.redirect_url;
				console.log(data);
			} else {
				console.log(data);
			}
		});
	} catch (error) {
    const errorMsg = {
      message: error
    };
    console.log(JSON.stringify(errorMsg));
	}
}
*/
async function fetchFillComarc() {
	const cid = document.getElementById('inputCobissId').value;
	const uid = document.getElementById('currentUserId').value;

	if (!cid) {
		alert("Prosim vstavi COBISS ID");
		return;
	}
	if (!uid) {
        alert("Prosim prijavi se");
        return;
	}

	try {
		const response = await fetch(`/process_data`, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
			},
			body: JSON.stringify({cid: cid, uid: uid}),
		})
		.then(response => response.json())
		.then(data => {
			console.log(data)
			if (data.status_code == 201) {
				window.location.href = data.redirect_url;
				//console.log(data);
			} else {
				alert(data.message);
				//console.log(data);
			}
		});
	} catch (error) {
    const errorMsg = {
      message: "Something ain't alright there ... :/"
    };
    console.log(JSON.stringify(errorMsg));
	}
}
document.addEventListener('DOMContentLoaded', () => {
  const isDeposit = document.getElementById('deposit-form');
  if (isDeposit) {
    const actions = [
      {id: "fetchFillComarc", action: fetchFillComarc},
      {id: "importFix", action: postRecord}
    ]
    actions.forEach(({ id, action }) => {
      document.getElementById(id).addEventListener("click", function(event) {
        event.preventDefault();
        action();
        });
    });
    const names = ['metadata.title','custom_fields.ukm:UUID', 'custom_fields.ukm:is_collection'];
    names.forEach(name => applyDescriptor(name));
    handleButtonClick('singleOrCollection', 'custom_fields.ukm:is_collection');
    getUUID();
    console.log("Beep beep, this is THE DEPOSIT form.");
  } else {
    console.log("Beep beep, this is javascript reporting.");
  }
});